<script>
import Layout from '../../layout/Layout'
import Menu from './menu'
import DefaultMixin from './mixin'

export default {
  name: 'SuporteClientePage',
  mixins: [DefaultMixin],
  components: {
    Layout
  },
  data () {
    return {
    }
  },
  computed: {
    menu () {
      return Menu
    }
  },
  beforeCreate() {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 10)
    document.body.classList.add('suporte-cliente-module-body')
    this.erplayout.erpheader.menu.setActiveMenu('suporte')
  },
  mounted () {
  },
  beforeDestroy() {
    document.body.classList.remove('suporte-cliente-module-body')
    this.erplayout.erpheader.setMenuModuleToggle(false)
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Suporte',
    name: 'Suporte'
  }
}
</script>

<template>
  <layout class="cli-s-container" :menu="menu" menu-style="black" menu-active="config" menu-name="SUPORTE" :folded="true" no-title hfull>
    <router-view />
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
